import Header from "../components/atoms/header";
import ManualContainer from "../components/atoms/ManualContainer/ManualContainer";
import React from "react";
import { Helmet } from "react-helmet";
function WiZRXManual() {
  return (
    <>
      <Helmet>
        <title>WiZR X Manual </title>
        <link rel="canonical" href="https://masterwizr.com/wizrxmanual" />
      </Helmet>
      <Header />
      <ManualContainer
        image={
          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673455313/Manuals/wizrx12manual_rmaylf.png"
        }
      />
    </>
  );
}
export default WiZRXManual;
